<template>
  <ConfigPage>
    <ConfigItem
      :title="__('Bloqueio - tipos de anexo')"
      :description="
        __(
          'Define quais os tipos de anexos são bloqueados para a integração com a EPS'
        )
      "
    >
      <v-card outlined class="mb-12">
        <div class="d-flex align-center justify-space-between px-2">
          <v-card-title class="pl-2">{{ __("Tipo") }}</v-card-title>
          <v-btn @click="loadItems()" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <v-chip-group column active-class="primary--text">
          <v-chip
            class="ml-3 ma-0 mt-2 mb-2"
            v-for="item in items"
            :key="item.id"
            :value="item.type"
            close
            @click:close="handleDelete(item.id)"
          >
            {{ item.type }}
          </v-chip>
        </v-chip-group>
        <div v-if="items.length === 0" class="empty-message grey--text ml-4">
          <span> {{ "Nenhum tipo de anexo bloqueado" }} </span>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="handleClickAdd()" color="primary ml-2">
            <v-icon left>mdi-plus</v-icon> {{ "Novo tipo" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ConfigItem>
    <EpsBlockedAttachmentTypesFormDialog
      ref="formDialog"
      @submitted="loadItems()"
    />
    <ConfirmationDialog ref="confirmDialog" />
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import { index, destroy } from "@/services/eps_blocked_attachment_types";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ConfirmationDialog from "@/components/tools/ConfirmationDialog";
import i18n from "@/mixins/i18n";
import EpsBlockedAttachmentTypesFormDialog from "@/components/EPS/EpsBlockedAttachmentTypesFormDialog.vue";

export default {
  mixins: [i18n, ToastsMixin],
  components: {
    ConfigPage,
    ConfigItem,
    ConfirmationDialog,
    EpsBlockedAttachmentTypesFormDialog,
  },

  data() {
    return {
      i18n_prefix: "settings",
      loading: false,
      items: [],
    };
  },

  methods: {
    async loadItems() {
      this.loading = true;
      this.items = [];
      try {
        const response = await index();

        this.items = response.data;
      } catch (error) {
        this.toastError(
          this.__(
            "Não foi possível carregar tipos de equipamentos, tente novamente mais tarde",
            "settings"
          )
        );
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete(id) {
      this.$refs.confirmDialog.confirm(
        this.__("confirmar", "settings"),
        async ({ close, startLoading, stopLoading }) => {
          startLoading();
          try {
            await destroy(id);
            close();
            this.items = this.items.filter((item) => item.id !== id);

            this.toast(
              this.__("Tipo de anexo removido com sucesso!", "settings")
            );
          } catch (error) {
            console.error("Erro ao excluir:", error);
            this.toastError(
              this.__("Erro ao remover o tipo de anexo.", "settings")
            );
          } finally {
            stopLoading();
          }
        },
        null,
        {
          description: this.__(
            "Tem certeza que deseja excluir este tipo de anexo?",
            "settings"
          ),
          confirmText: this.__("Sim, Excluir", "settings"),
          cancelText: this.__("cancelar", "settings"),
          color: "red",
        }
      );
    },

    handleClickAdd() {
      this.$refs.formDialog.open();
    },
  },

  created() {
    this.loadItems();
  },
};
</script>
