<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-form @submit.prevent="handleSubmit">
      <v-card :disabled="loading">
        <v-card-title>
          {{ __("Formulário - Tipo de anexo a ser bloqueado") }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.type"
            :label="__('Tipo')"
            autofocus
            outlined
            counter="10"
            maxlength="10"
          ></v-text-field>
          <span v-if="error.type" class="error--text text-caption">
            {{ error.type[0] }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> {{ __("cancelar") }} </v-btn>
          <v-btn type="submit" color="primary" dark :loading="loading">
            {{ __("Salvar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { store } from "@/services/eps_blocked_attachment_types.js";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import i18n from "@/mixins/i18n";

export default {
  mixins: [ToastsMixin, i18n],

  data() {
    return {
      i18n_prefix: "settings",
      dialog: false,
      loading: false,
      form: {
        type: "",
      },
      error: {},
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.resetForm();
    },

    close() {
      this.error = {};
      this.dialog = false;
    },

    resetForm() {
      this.error = {};
      this.form = {
        type: "",
      };
    },

    async handleCreate() {
      this.loading = true;
      this.error = {};

      try {
        const response = await store(this.form);

        this.$emit("submitted", response);
        this.close();
        this.toast(this.__("Tipo registado com sucesso", "settings"));
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400) {
          this.toastError(error.response.data.message);
          return;
        }
        if (error.response?.status === 422) {
          this.error = error.response.data.errors;
          return;
        }
        this.toastError(this.__("Não foi possível registar", "settings"));
      } finally {
        this.loading = false;
      }
    },

    handleSubmit() {
      this.handleCreate();
    },
  },
};
</script>
