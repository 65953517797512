import Vue from "vue";

export async function index() {
  const response = await Vue.prototype.$http.get(
    "/configs/eps/blocked_attachment_types"
  );

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post(
    "/configs/eps/blocked_attachment_types",
    payload
  );

  return response.data.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete(
    "/configs/eps/blocked_attachment_types/" + id
  );

  return true;
}
